import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import BannerSection from "../components/Global/TopBanner"
import Portfolio from "../components/Work/Portfolio"

const WorkPage = ({ data }) => (
  <Layout>
    <SEO
      title="Showcase"
      keywords={[
        `showcase`,
        `portfolio`,
        `web app`,
        `mobile app`,
        `ecommerce`,
        `blockchain`,
        `UI/UX`,
      ]}
    />
    <BannerSection
      img={data.img.childImageSharp.fluid}
      title="We know our stuff."
    />
    <Portfolio items={data.project} />
  </Layout>
)

export const query = graphql`
  {
    img: file(relativePath: { eq: "portfolio-banner.png" }) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    project: allContentfulProject(sort: { fields: createdAt, order: DESC }) {
      edges {
        node {
          id
          name
          client
          types
          technologies
          images {
            fluid {
              ...GatsbyContentfulFluid
            }
          }
          description {
            id
          }
        }
      }
    }
  }
`

export default WorkPage
